.slider {
  align-items: center;
  display: flex;
  height: 600px;
  margin: 0 auto;
  position: relative;
  align-items: center;
}

.image {
  width: 100%;
  height: 500px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  object-fit: contain;
}
.image_active{
  width: 100%;
  height: 500px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  object-fit: contain;
  box-shadow: 0 0 30px rgb(255 255 255 / 60%), 0 0 60px rgb(255 255 255 / 45%),
  0 0 110px rgb(255 255 255 / 25%), 0 0 100px rgb(255 255 255 / 10%);
}

/* box-shadow: 0 0 30px rgb(255 255 255 / 60%), 0 0 60px rgb(255 255 255 / 45%),
  0 0 110px rgb(255 255 255 / 25%), 0 0 100px rgb(255 255 255 / 10%);*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide_active {
  position: absolute;
  width: 500px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition: all 01s ease-in-out;
  filter: brightness(1.2);
}

.slide_prev {
  width: 400px;
  opacity: 0.4;
  left: 30%;
  transform: translateX(-50%);
  position: absolute;
  transition: all 01s ease-in-out;
  z-index: 1;
  filter: blur(5px);
}
.slide_next {
  width: 400px;
  opacity: 0.4;
  left: 70%;
  transform: translateX(-50%);
  position: absolute;
  transition: all 01s ease-in-out;
  z-index: 1;
  filter: blur(5px);
}

.slide_pprev {
  width: 300px;
  opacity: 0.2;
  left: 15%;
  transform: translateX(-50%);
  position: absolute;
  transition: all 01s ease-in-out;
  z-index: 0;
  filter: blur(5px);
}

.slide_nnext {
  width: 300px;
  opacity: 0.2;
  left: 85%;
  transform: translateX(-50%);
  position: absolute;
  transition: all 01s ease-in-out;
  z-index: 0;
  filter: blur(5px);
}
.right_arrow {
  position: absolute;
  top: 50%;
  font-size: 120px;
  right: 0px;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  transition: all linear 0.2s;
}

.left_arrow {
  position: absolute;
  top: 50%;
  font-size: 120px;
  left: 0px;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  transition: all linear 0.2s;
}

.slider:hover .right_arrow {
  opacity: 1;
}
.slider:hover .left_arrow {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .slider {
    align-items: center;
    display: flex;
    height: 300px;
    margin: 0 auto;
    position: relative;
    align-items: center;
  }
  .image {
    width: 100%;
    max-height: 500px;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    border-radius: 10px;
    background-color: #fff;
    object-fit: contain;
  }
  .slide_active {
    position: absolute;
    width: 300px;
    opacity: 1;
    left: 50%;
    box-shadow: 0 0 30px rgb(255 255 255 / 60%), 0 0 60px rgb(255 255 255 / 45%),
      0 0 110px rgb(255 255 255 / 25%), 0 0 100px rgb(255 255 255 / 10%);
    transform: translateX(-50%);
    z-index: 2;
    transition: all 0.3s ease-in-out;
  }

  .slide_prev {
    width: 200px;
    opacity: 0.6;
    left: 30%;
    transform: translateX(-50%);
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }
  .slide_next {
    width: 200px;
    opacity: 0.6;
    left: 70%;
    transform: translateX(-50%);
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  .slide_pprev {
    display: none;
  }

  .slide_nnext {
    display: none;
  }
  .right_arrow {
    position: absolute;
    top: 50%;
    font-size: 60px;
    right: 0px;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transition: all linear 0.2s;
  }
  
  .left_arrow {
    position: absolute;
    top: 50%;
    font-size: 60px;
    left: 0px;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transition: all linear 0.2s;
  }
}


