.playlistbody{
  background:black;
}
.tablebody {
  vertical-align: middle;
}
.btncontrols {
  border-radius: 50%;
  font-size: 25px;
  padding-top: 3px;
  padding-bottom: 30px;
  background: black;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
  transition: all linear 0.2s;
}
.btncontrols:hover {
  background-color: white;
  padding-top: 0px;
  font-size: 30px;
  color: black;
  border-radius: 15px;
}

.listview {
  margin-top: 60px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 500px;
  overflow: scroll;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.listview::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .title {
      margin-top: 100px;
  }
  .listview {
    margin-top: 0px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    max-height: 500px;
    overflow: scroll;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

.player {
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: red;
}
.playinfo {
  display: flex;
  padding: 0px 30px 0px 30px;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  padding: 10px;
  margin-right: 10px;
}
.info {
  align-self: center;
}
