.body{
    z-index: 9;
    padding: 0;
    background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
}

.link{
    font-size: 20px;
    margin-right: 10px;
    min-height: 50px;
    transition: all linear 0.2s;
}

.link:hover{
    background-color: black;
}

.btnlogout{
    border-radius: 50%;
    transition: all linear 0.2s;
    font-size: 22px;
}

.btnlogout:hover{
    background-color: white;
    color:black
}

.brand{
    font-size: 30px;
    font-weight: bolder;
}